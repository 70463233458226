<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg8">
    <div class="container">
        <div class="inner-title">
            <h3>Services</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Services</li>
            </ul>
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <h2>FOOD</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/restaurant.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Restaurant Food</h4>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/fruits-and-veg.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Vegetables & Fruits</h4>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/groceries.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Groceries</h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/service/pickles.jpg" alt="Blog Images"></a>

                    <div class="blog-content">
                        <h4>Pickles</h4>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/service/street-food.jpg" alt="Blog Images"></a>

                    <div class="blog-content">
                        <h4>Street Food </h4>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/service/meat1.jpg" alt="Blog Images"></a>

                    <div class="blog-content">
                        <h4>Meat</h4>
                    </div>
                </div>
            </div>

        
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <h2>GROCERIES</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/wheat1.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Wheat Flour</h4>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/black-gram1.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Black Grams</h4>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/dal1.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Dal</h4>
                    </div>
                </div>
            </div>
       
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <h2>VEGETABLES AND FRUITS</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/carrot1.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Carrot</h4>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/cauliflower1.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Cauliflower</h4>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/tomato1.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Tomato</h4>
                    </div>
                </div>
            </div>
       
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/grapes1.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Grapes</h4>
                    </div>
                </div>
            </div>
       
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/apples1.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Apples</h4>
                    </div>
                </div>
            </div>
       
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/banana.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Banana</h4>
                    </div>
                </div>
            </div>
       
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <h2>STREET FOOD</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/shawarma1.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Shawarma</h4>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/mirchi-bajji1.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Mirchi Bajji</h4>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/pav-bhaji.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Pav Bhaji</h4>
                    </div>
                </div>
            </div>
       
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <h2>MEAT AND FISH</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/chicken.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Chicken</h4>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/mutton1.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Mutton</h4>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <img src="assets/img/service/fish1.jpg" alt="Blog Images">

                    <div class="blog-content">
                        <h4>Fish</h4>
                    </div>
                </div>
            </div>
       
        </div>
    </div>
</div>





<app-footer-style-one></app-footer-style-one>