import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormserviceService } from 'src/app/formservice.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  formfive:FormGroup;

  constructor(private formbuilder : FormBuilder, private service : FormserviceService) { 
    this.formfive = this.formbuilder.group({
      name:[''],
      email:[''],
      phn:[''],
      msg_sub:[''],
      msg:['']
    
    })
  }

  ngOnInit(): void {
  }

  fiveSubmit(){
    console.log(this.formfive.value);
    
    this.service.formfiveservfun(this.formfive.value).subscribe((res: any) => {
     if (res.status == 200) {
       alert("submitted")
       this.formfive.reset();
     } else {
       alert('server down')
     }
   })
    
  }
}
