<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/footer-logo.png" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a href="javascript:void(0)" routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Home </a>

                        </li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li>
                        <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>
                        <li class="nav-item"><a routerLink="/service-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Franchise/Suppliers</a></li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>


                    <div class="menu-btn">
                        <a href="tel:+11234567890" class="phone-btn"><i class='bx bxs-phone'></i> +91 7799822811</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>