import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormserviceService {

  Api = 'http://localhost:1234/nodeapp/'


  constructor(private http : HttpClient) { }

  formoneservfun(data: any) {
    console.log(data,'service');
    
    return this.http.post(this.Api + 'postformone', data)
  }


  formtwoservfun(data: any) {
    console.log(data,'service');
    
    return this.http.post(this.Api + 'postformtwo', data)
  }

  formthreeservfun(data: any) {
    console.log(data,'service');
    
    return this.http.post(this.Api + 'postformthree', data)
  }

  formfourservfun(data: any) {
    console.log(data,'service');
    
    return this.http.post(this.Api + 'postformfour', data)
  }
 
  formfiveservfun(data: any) {
    console.log(data,'service');
    
    return this.http.post(this.Api + 'postformfive', data)
  }
}
