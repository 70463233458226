<footer class="footer-area">
    <div class="container">

        <div class="footer-top-list pb-70 pt-100">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="footer-logo">
                        <img src="assets/img/footer-logo.png" alt="Footer Logo">
                        <div class="pt-45">
                            <p>Lampsi offers franchise management, supplier integration, and customer tools to grow and elevate your business.</p>
                        </div>
                        
                    </div>
                    <!-- <div class="footer-list">
                        <h3>Services</h3>

                        <ul>
                            <li><i class='bx bxs-chevron-right'></i> <a routerLink="/services">Brand Identy</a></li>
                            <li><i class='bx bxs-chevron-right'></i> <a routerLink="/services">Package Design</a></li>
                            <li><i class='bx bxs-chevron-right'></i> <a routerLink="/services">Pattern Design</a></li>
                            <li><i class='bx bxs-chevron-right'></i> <a routerLink="/services">T-shirt Print</a></li>
                            <li><i class='bx bxs-chevron-right'></i> <a routerLink="/services">Book Covers</a></li>
                            <li><i class='bx bxs-chevron-right'></i> <a routerLink="/services">CD-DVD Covers</a></li>
                        </ul>
                    </div> -->
                </div>

                <div class="col-lg-3 col-md-3">
                    <div class="footer-list">

                        <h3>Quick Links</h3>
                        <ul>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/">Home</a></li>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/about">About Us</a></li>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/services">Service</a></li>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/service-details">Testimonial</a></li>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-5 col-md-5">
                    <div class="footer-side-list">
                        <h3>Contact Us</h3>

                        <ul>
                            <li><i class='bx bxs-phone'></i> <a href="tel:+11234567891">+1 1234 56 7891</a></li>
                            <li><i class='bx bxs-map'></i> <a >Plot no: 105/A,</a></li>
                            <li><i class='bx bxs-map'></i> <a>Telukulavari Cheruvu Street,</a></li>
                            <li><i class='bx bxs-map'></i> <a>Satyanarayanapuram</a></li>
                            <li><i class='bx bxs-map'></i> Rajahmundry, Andhra Pradesh</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-3">
                    <div class="footer-logo">
                        <img src="assets/img/footer-logo.png" alt="Footer Logo">
                    </div>
                </div>

                <div class="col-lg-8 col-md-9">
                    <div class="bottom-text">
                        <p>Copyrights © <a href="https://wafu.dev/" target="_blank">Lampsi</a>
                        <p> <a href="/terms-condition" target="_blank">&nbsp;&nbsp;Terms & Conditions</a></p>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>