<app-navbar-style-one></app-navbar-style-one>

<div class="main-banner">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="banner-content">
                    <h1>Empowering Food Businesses & Delivering Freshness</h1>    
                    <p>Lampsi is your all-in-one platform to elevate food businesses and delivery services. From managing diverse shops to reaching more customers, our tools, dashboards, and apps ensure your success.</p>
                    <div class="banner-btn">
                        <a routerLink="/about" class="default-btn">Know More</a>
                        <a routerLink="/services" class="default-btn active">Our Products</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 pr-0">
                <div class="banner-img">
                    <img src="assets/img/home1/home2.jpg" alt="Banner Images">

                    <div class="banner-into-slider owl-carousel owl-theme">
                        <div class="banner-item">
                            <img src="assets/img/home1/home1.jpg" alt="Banner Images">
                        </div>

                        <div class="banner-item">
                            <img src="assets/img/home1/home3.jpg" alt="Banner Images">
                        </div>

                        <div class="banner-item">
                            <img src="assets/img/home1/home4.jpg" alt="Banner Images">
                        </div>

                        <div class="banner-item">
                            <img src="assets/img/home1/home.jpg" alt="Banner Images">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="testimonial-area pb-100 pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7">
                <div class="testimonial-content">
                    <div class="scetion-title text-center">
                        <h2>Welcome To Lampsi</h2>
                        <p>Lampsi is your one-stop solution for managing and growing food businesses. Whether you're running a grocery, restaurant, or any food-related service, our platform offers the tools you need for seamless operations, customer engagement, and business growth. Join us and discover a smarter way to manage your business.</p>
                        <p>Our comprehensive platform provides a franchise management system, supplier integration, and customizable dashboards, empowering you to streamline operations and enhance customer experiences. With Lampsi, you’ll have everything you need to take your business to the next level.</p>
                    </div>

                   
                </div>
            </div>

            <div class="col-lg-5">
                <div class="testimonial-img">
                    <div class="testimonial-bg-shape">
                        <img src="assets/img/testimonial/bg-shape.png" alt="Testimonial Images">
                    </div>

                    <div class="testominail-man">
                        <img src="assets/img/home1/delivery.jpg" alt="Testimonial Shape">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="service-area pb-70 pt-45">
    <div class="container">
        <div class="scetion-title text-center">
            <span> Our Services</span>
            <h2>Offering a Diverse Range of Services </h2>
            <p>Experience a diverse range of services tailored to meet all your business needs, from everyday essentials to specialty items</p>
        </div>

        <div class="service-slider owl-carousel owl-theme pt-45">
            <div class="service-card">
                <a routerLink="/service-details"><img src="assets/img/home1/carrot.webp" alt="Service Images"></a>

                <div class="service-content">
                    <h3>Vegetables</h3>
                </div>
            </div>

            <div class="service-card">
                <a routerLink="/service-details"><img src="assets/img/home1/groceries.webp" alt="Service Images"></a>

                <div class="service-content">
                    <h3>Groceries</h3>
                </div>
            </div>

            <div class="service-card">
                <a routerLink="/service-details"><img src="assets/img/home1/mirchi-bajji1.jpg" alt="Service Images"></a>

                <div class="service-content">
                    <h3>Street Food</h3>
                </div>
            </div>

            <div class="service-card">
                <a routerLink="/service-details"><img src="assets/img/home1/apples1.jpg" alt="Service Images"></a>

                <div class="service-content">
                    <h3>Fruits</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="choose-area ptb-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Why Choose Us</span>
            <h2>Your Partner for Smarter Business Growth</h2>
            <p>Choose Lampsi for seamless business management, tailored tools, and unmatched support to help your food business thrive.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-3 col-sm-6">
                <div class="choose-card">
                    <i class='bx bx-like'></i>
                    <h3>Safety</h3>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="choose-card">
                    <i class='bx bx-box'></i>
                    <h3>Trusted Company</h3>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="choose-card">
                    <i class='bx bxs-truck'></i>
                    <h3>Fast Service</h3>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="choose-card">
                    <i class='bx bx-like'></i>
                    <h3>Better Experience</h3>
                </div>
            </div>

           
        </div>
    </div>
</section>




<app-footer-style-one></app-footer-style-one>