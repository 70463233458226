import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormserviceService } from 'src/app/formservice.service';

@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss']
})
export class ServicesDetailsComponent implements OnInit {
  formone:FormGroup;
  formtwo:FormGroup;
  formthree:FormGroup;
  formfour:FormGroup;

  constructor(private formbuilder : FormBuilder, private service : FormserviceService) {
    this.formone = this.formbuilder.group({
      fname:[''],
      lname:[''],
      gen:[''],
      email:[''],
      phn:[''],
      altphn:[''],
      education:[''],
      address:[''],
      city:[''],
      district:[''],
      state:[''],
      pincode:[''],
      branch:[''],
      msg:['']
 
 
     })
 
 
     this.formtwo = this.formbuilder.group({
       fname:[''],
       lname:[''],
       gen:[''],
       email:[''],
       phn:[''],
       altphn:[''],
       address:[''],
       city:[''],
       district:[''],
       state:[''],
       pincode:[''],
       suppliertype:[''],
       msg:['']
  
  
      })
    
 
      this.formthree = this.formbuilder.group({
       fname:[''],
       lname:[''],
       gen:[''],
       email:[''],
       phn:[''],
       altphn:[''],
       address:[''],
       city:[''],
       district:[''],
       state:[''],
       pincode:[''],
       joblocation:[''],
       qualification:[''],
       smartphn:[''],
       vehicle:[''],
       licence:[''],
       msg:['']
  
  
      })
 
 
      this.formfour = this.formbuilder.group({
       fname:[''],
       lname:[''],
       gen:[''],
       email:[''],
       phn:[''],
       altphn:[''],
       address:[''],
       city:[''],
       district:[''],
       state:[''],
       pincode:[''],
       joblocation:[''],
       qualification:[''],
       experience:[''],
       msg:['']
  
  
      })
   }

  ngOnInit(): void {
  }

  moreSubmit(){
    console.log(this.formone.value);
    
    this.service.formoneservfun(this.formone.value).subscribe((res: any) => {
     if (res.status == 200) {
       alert("submitted")
       this.formone.reset();
     } else {
       alert('server down')
     }
   })
    
  }


  twoSubmit(){
    console.log(this.formtwo.value);
    
    this.service.formtwoservfun(this.formtwo.value).subscribe((res: any) => {
     if (res.status == 200) {
       alert("submitted")
       this.formtwo.reset();
     } else {
       alert('server down')
     }
   })
    
  }


  threeSubmit(){
    console.log(this.formthree.value);
    
    this.service.formthreeservfun(this.formthree.value).subscribe((res: any) => {
     if (res.status == 200) {
       alert("submitted")
       this.formthree.reset();
     } else {
       alert('server down')
     }
   })
    
  }


  fourSubmit(){
    console.log(this.formfour.value);
    
    this.service.formfourservfun(this.formfour.value).subscribe((res: any) => {
     if (res.status == 200) {
       alert("submitted")
       this.formfour.reset();
     } else {
       alert('server down')
     }
   })
    
  }
}
