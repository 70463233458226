<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg13">
    <div class="container">
        <div class="inner-title">
            <h3>Terms & Conditions</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Terms & Conditions</li>
            </ul>
        </div>
    </div>
</div>

<div class="terms-conditions-area ptb-100">
    <div class="container">
        <div class="single-content">
            <h3>1.Introduction</h3>
            <p>This policy explains how we, Lampsi INFRA &amp; MERCHANDISE PRIVATE LIMITED (hereinafter referred to as
                "Lampsi"), use the personal information which you provide to us when using our service, including but not
                limited to our website and mobile applications (jointly referred as “Website”). Please read this Privacy
                Policy
                and understand it before using our services. By visiting and/or ordering services on this Website, you agree
                and, where required, consent to the collection, use and transfer of your information as set out in this
                policy.
                </p>

                <h4>2. What information do we collect about you? </h4>

            <p>We collect personal information from you when you order goods or services from us or use our Website. We
                also collect information when you complete any customer survey. Website usage information may also be
                collected
                using cookies (as defined below). Lampsi will collect information that personally identifies you and/or your
                location, where required (this may include your name, email address, home address, telephone number,
                geolocation, etc.), but only when you voluntarily give it to us. We collect this information exclusively to
                carry out the functions offered on the Website and to provide you with offers and information about Lampsi
                and
                other services we think you may be interested in. We might collect this personal information through: online
                food ordering; entry into competitions; subscribing to our newsletter; creating a user account; sending
                'contact
                us' messages or other correspondence through the Website; or through advertising, research and direct
                marketing.
                We do not collect sensitive information about you.
                </p>

            <h3>3. Cookies </h3>
            <p>Some of the information collected will not personally identify you but will instead track your use of
            the
            Website so that we can better understand how the Website is used by customers and in turn enhance and
            improve
            your experience in ordering food. We may obtain this information by use of cookies. Cookies are a small data
            file transferred to your device that recognises and identifies your device and allows your device to
            'remember'
            information from the Website for future use. Cookies do not contain any information that personally
            identifies
            you and we do not use cookies in order to obtain such information - your personal information can only be
            obtained by Lampsi if you actively provide it to us. We may collect technical information from your mobile
            device or your use of our services through a mobile device, for example, location data and certain
            characteristics of, and performance data about your device, carrier/operating system including device and
            connection type, IP address, mobile payment methods, interaction with other retail technology such as use of
            NFC
            Tags, QR Codes or use of mobile vouchers. Your device and/or the web browser should allow you to refuse
            cookies
            if you wish by changing the settings. To find out more about cookies, including how to see what cookies have
            been set and how to manage and delete them, visit www.allaboutcookies.org.

            </p>
            
            <h4>4. Storage and security of your personal information </h4>
            <p> Lampsi will use all reasonable endeavours to maintain the security of your personal information and to
                protect your personal information from misuse, interference and loss and against unauthorised access,
                modification or disclosure. Lampsi will destroy any personal information it holds about you which it no
                longer
                requires under the terms of this Privacy Policy. Where you have chosen a password to access certain services
                of
                the Website, you are responsible for keeping your password confidential. We recommend not sharing your
                password
                with anyone. Due to the nature of the internet, Lampsi does not provide any guarantee or warranty regarding
                the
                security of your personal information during transmission to us or storage by us and you acknowledge that
                you
                disclose your personal information to us at your own risk. Please contact us immediately if you become aware
                or
                have reason to believe there has been any unauthorised use of your personal information in connection with
                the
                Website.
                </p>
           <h4>5. How will we use the information we collect from you? </h4>
           <p>You consent to Lampsi collecting and using your personal information for processing and delivering the
            order/s placed on the Website and any other service provided through the Website. In particular you
            expressly
            consent that Lampsi may disclose your personal information, including your name, email address, physical
            address
            and telephone number to riders delivering your order, either employed by Lampsi or by third parties
            providing
            delivery services to Lampsi. You consent to Lampsi using your personal information for advertising and
            direct
            marketing purposes in order to inform you about the Website and the goods and services it provides, or other
            matters that it believes will be of interest to you. Lampsi may disclose your personal information, and you
            consent to the disclosure of your personal information, to: other entities within the Lampsi group of
            companies;
            and third parties engaged by Lampsi or other members of the Lampsi group to perform functions or provide
            products and services on our behalf such as processing payments, mail outs, debt collection, research,
            statistical information, marketing and direct or indirect advertising.
            </p>
            <p>If you would like to unsubscribe from receiving direct marketing communications from Lampsi or do not
                want
                us to share your personal information with other parties, please e-mail us at hello&#64;Lampsi.co or follow the
                steps to unsubscribe which are presented in every communication you receive from us, requesting your
                personal
                information be removed from our mailing list. We will use reasonable endeavours to comply with your request
                within a reasonable period of receipt of your request. Please note that the withdrawal of any authorisations
                for
                processing of your personal information by third parties may result in us not being able to provide you with
                any
                services.
                </p>
                <p>Lampsi reserves the right to disclose your personal information if it is required or authorised to do so
                    by
                    law, or, if it is reasonably necessary in its opinion to protect the rights or property of Lampsi or any
                    third
                    party, or to avoid injury to any person. If the Lampsi business is sold or merges with another entity then
                    some
                    or all of your personal information may be passed to a third party.
        
                    </p>

            <h4>6. Access to your information </h4>
            <p>Lampsi will use all reasonable endeavours to keep personal information it holds accurate, complete,
                up-to-date, relevant and not misleading. Please contact us if you would like to access the personal
                information
                Lampsi holds about you. We will use reasonable endeavours to provide a complete list of your personal
                information within a reasonable period of receipt of your request. Please email us if you would like to
                receive
                a copy of this information – hello&#64;Lampsi.co. Lampsi reserves the right to charge a nominal fee for the
                processing of this request in accordance with local legislation. You may contact us to correct any of your
                personal information that is inaccurate, incomplete or out-of-date, or to request that your personal
                information
                be deleted. We will use reasonable endeavours to correct or delete your personal information as requested
                within
                a reasonable period of receipt of your request. Deletion of your account with Lampsi will not automatically
                delete the personal information held about you. If you would like Lampsi to delete all personal information
                together with the deletion of your account, please follow the steps mentioned-above. Please note that the
                deletion of your personal information from our database will result in us not being able to provide you with
                any
                services. If applicable, any legal requirement on us to maintain certain records of your personal
                information
                shall prevail over any of your requests. We may require you to prove your identity before providing you with
                copies of your personal information.
    
                </p>
                <h4>7. Changes to our Privacy Policy</h4>
                <p>Lampsi reserves the right to alter all or any part of this Privacy Policy. Any changes thereto will be
                    notified via the Website and, where appropriate, through e-mail notification.
        </p>
                   <h4>8. Secure Online Payments</h4>
                   <p>The visitor on our website please take a note that your name, email address and other personal
                    information
                    submitted on our website may be stored with us and may also appear on the website. Like other platforms our
                    server log files also receives general information such as IP address of the visitor, cookie etc. For the
                    financial transactions by credit card Lampsi Infra & Merchandise Private Limited uses a 3rd party secure
                    payment gateway provided by “PayU” and the credit card details are ‘not stored’ with LAMPSI INFRA &
                    MERCHANDISE PRIVATE LIMITED, instead the information is securely stored and encrypted with Visa/MasterCard.
        
                    </p>
                    <h4>9. Other Websites</h4>
                    <p>Our Website may have links to other websites. This privacy policy only applies to the Website. You
                        should
                        therefore read the privacy policies of the other websites when you are using those sites.
            
                        </p>

                        <h4>10. Contact</h4>
                        <p>All comments, queries and requests relating to our use of your information are welcomed and should be
                            addressed to: hello&#64;Lampsi.co</p>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>