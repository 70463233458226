<div class="preloader">
    <div class="d-table">
        <div class="d-table-cell">
            <!-- <div class="pre-img">
                <img src="assets/img/footer-logo.png" alt="Logo">
            </div> -->
            
            <div class="spinner">
                <div class="circle1"></div>
                <div class="circle2"></div>
                <div class="circle3"></div>
            </div>
        </div>
    </div>
</div>