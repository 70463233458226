<footer class="footer-area">
    <div class="container">

        <div class="footer-top-list pb-70">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="footer-list">
                        <h3>Services</h3>

                        <ul>
                            <li><i class='bx bxs-chevron-right'></i> <a routerLink="/services">Brand Identy</a></li>
                            <li><i class='bx bxs-chevron-right'></i> <a routerLink="/services">Package Design</a></li>
                            <li><i class='bx bxs-chevron-right'></i> <a routerLink="/services">Pattern Design</a></li>
                            <li><i class='bx bxs-chevron-right'></i> <a routerLink="/services">T-shirt Print</a></li>
                            <li><i class='bx bxs-chevron-right'></i> <a routerLink="/services">Book Covers</a></li>
                            <li><i class='bx bxs-chevron-right'></i> <a routerLink="/services">CD-DVD Covers</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3">
                    <div class="footer-list">

                        <h3>Quick Links</h3>
                        <ul>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/index">Home</a></li>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/services">Service</a></li>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/about">About Us</a></li>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/testimonial">Testimonial</a></li>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/blog">Blog</a></li>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-5 col-md-5">
                    <div class="footer-side-list">
                        <h3>Contact Us</h3>

                        <ul>
                            <li><i class='bx bxs-phone'></i> <a href="tel:+11234567891">+1 1234 56 7891</a></li>
                            <li><i class='bx bxs-map'></i> <a href="tel:+19876543210">+1  9876 543 210</a></li>
                            <li><i class='bx bxs-map'></i> <a href="mailto:email&#64;dizo.com">email&#64;dizo.com</a></li>
                            <li><i class='bx bxs-map'></i> <a href="mailto:hello&#64;dizo.com">hello&#64;dizo.com</a></li>
                            <li><i class='bx bxs-map'></i> 28/A Street, New York, USA</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-3">
                    <div class="footer-logo">
                        <img src="assets/img/footer-logo.png" alt="Footer Logo">
                    </div>
                </div>

                <div class="col-lg-8 col-md-9">
                    <div class="bottom-text">
                        <p>© Dizo is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                        <ul class="social-bottom">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>