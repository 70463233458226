<div class="navbar-area">
    
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/footer-logo.png" alt="Logo"></a>
    </div>

    <div class="top-nav main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Home <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Three</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Pages <i class='bx bx-plus'></i></a>
                            
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/designer" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Designer</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 page</a></li>

                                <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign In</a></li>

                                <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/recover-password" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Recover Password</a></li>

                                <li class="nav-item"><a routerLink="/terms-condition" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>

                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link">Level 1 <i class='bx bx-plus'></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Level 2</a></li>

                                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Level 2</a></li>

                                        <li class="nav-item">
                                            <a href="javascript:void(0)" class="nav-link">Level 2 <i class='bx bx-plus'></i></a>

                                            <ul class="dropdown-menu">
                                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Level 3</a></li>

                                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Level 3</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Services <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services </a></li>

                                <li class="nav-item"><a routerLink="/service-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Products <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products</a></li>

                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>
                                
                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>

                                <li class="nav-item"><a routerLink="/product-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Product Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Blog <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>

                    <div class="cart-area">
                        <a routerLink="/cart">
                            <i class='bx bx-cart'></i>
                        </a>
                    </div>

                    <div class="modal-menu">
                        <a href="#" data-toggle="modal" data-target="#myModal2">
                            <i class='bx bx-menu'></i>
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>

<div class="sidebar-modal">
    <div class="modal right fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">
                            <i class="bx bx-x"></i>
                        </span>
                    </button>

                    <h2 class="modal-title" id="myModalLabel2">
                        <a routerLink="/">
                            <img src="assets/img/logo.png" alt="Logo">
                        </a>
                    </h2>
                </div>
                
                <div class="modal-body">
                    <div class="sidebar-modal-widget">
                        <h3 class="title">About Us</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, asperiores doloribus eum laboriosam praesentium delectus unde magni aut perspiciatis cumque deserunt dolore voluptate, autem pariatur.</p>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Blog Links</h3>

                        <div class="modal-widget-blog">
                            <ul>
                                <li><a routerLink="/blog-details"><img src="assets/img/blog/blog1.jpg" alt="Blog Images"></a></li>
                                <li><a routerLink="/blog-details"><img src="assets/img/blog/blog2.jpg" alt="Blog Images"></a></li>
                                <li><a routerLink="/blog-details"><img src="assets/img/blog/blog3.jpg" alt="Blog Images"></a></li>
                                <li><a routerLink="/blog-details"><img src="assets/img/blog/blog4.jpg" alt="Blog Images"></a></li>
                                <li><a routerLink="/blog-details"><img src="assets/img/blog/blog5.jpg" alt="Blog Images"></a></li>
                                <li><a routerLink="/blog-details"><img src="assets/img/blog/blog6.jpg" alt="Blog Images"></a></li>
                                <li><a routerLink="/blog-details"><img src="assets/img/blog/blog7.jpg" alt="Blog Images"></a></li>
                                <li><a routerLink="/blog-details"><img src="assets/img/blog/blog8.jpg" alt="Blog Images"></a></li>
                            </ul>
                        </div>
                    </div>
                    
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Contact Info</h3>

                        <ul class="contact-info">
                            <li>
                                <i class='bx bx-map'></i>
                                Address
                                <span>28/A Street, New York, USA</span>
                            </li>
                            <li>
                                <i class='bx bxs-paper-plane'></i>
                                Email
                                <span>hello&#64;dizo.com</span>
                            </li>
                            <li>
                                <i class='bx bxs-phone-call'></i>
                                Phone
                                <span>+1 1234 56 7891, +1  9876 543 210</span>
                            </li>
                        </ul>
                    </div>
                    
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Connect With Us</h3>

                        <ul class="social-list">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>