<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title">
            <h3>Franchise/Suppliers</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Franchise/Suppliers</li>
            </ul>
        </div>
    </div>
</div>

<br><br>
<div class="container mt-5">
    <div class="row">
        <!-- Form 1 -->
        <div class="col-md-6 mb-4">
            <div class="form-container">
                <h4 class="form-heading">FRANCHISE REGISTRATION FORM</h4>
                <form (ngSubmit)="moreSubmit()" [formGroup]="formone">
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field1">First Name</label>
                            <input type="text" class="form-control" id="field1" placeholder="Your Name"
                                formControlName="fname">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field2">Last Name</label>
                            <input type="text" class="form-control" id="field2" placeholder="Last Name"
                                formControlName="lname">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field3">Gender</label>
                            <input type="text" class="form-control" id="field3" placeholder="Gender"
                                formControlName="gen">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">Email Address</label>
                            <input type="email" class="form-control" id="field4" placeholder="Email Id"
                                formControlName="email">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field3">Phone Number</label>
                            <input type="number" class="form-control" id="field3" placeholder="Phone Number"
                                formControlName="phn">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">Alternative Phone Number</label>
                            <input type="number" class="form-control" id="field4" placeholder="Alternative Phone Number"
                                formControlName="altphn">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field3">Education Qualification</label>
                            <input type="text" class="form-control" id="field3" placeholder="Qualification"
                                formControlName="education">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">Address</label>
                            <input type="text" class="form-control" id="field4" placeholder="Address"
                                formControlName="address">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field3">City</label>
                            <input type="text" class="form-control" id="field3" placeholder="City"
                                formControlName="city">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">District</label>
                            <input type="text" class="form-control" id="field4" placeholder="district"
                                formControlName="district">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field3">State</label>
                            <input type="text" class="form-control" id="field3" placeholder="State"
                                formControlName="state">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">Pincode</label>
                            <input type="number" class="form-control" id="field4" placeholder="Pincode"
                                formControlName="pincode">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field3">Branch Location(Where do you want to take franchise?)</label>
                            <input type="text" class="form-control" id="field3" placeholder="Enter Branch Location"
                                formControlName="branch">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">Message</label>
                            <input type="text" class="form-control" id="field4" placeholder="Message"
                                formControlName="msg">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 text-center">
                        <button type="submit" class="default-btn page-btn text-center" style="border: none;">Send Message</button>
                     </div>
                </form>
            </div>
        </div>
        <!-- Form 2 -->
        <div class="col-md-6 mb-4">
            <div class="form-container">
                <h4 class="form-heading">SUPPLIER REGISTRATION FORM</h4>
                <form (ngSubmit)="twoSubmit()" [formGroup]="formtwo">
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field1">First Name</label>
                            <input type="text" class="form-control" id="field1" placeholder="Your Name"
                                formControlName="fname">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field2">Last Name</label>
                            <input type="text" class="form-control" id="field2" placeholder="Last Name"
                                formControlName="lname">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field3">Gender</label>
                            <input type="text" class="form-control" id="field3" placeholder="Gender"
                                formControlName="gen">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">Email Address</label>
                            <input type="email" class="form-control" id="field4" placeholder="Email Id"
                                formControlName="email">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field3">Phone Number</label>
                            <input type="number" class="form-control" id="field3" placeholder="Phone Number"
                                formControlName="phn">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">Alternative Phone Number</label>
                            <input type="number" class="form-control" id="field4" placeholder="Alternative Phone Number"
                                formControlName="altphn">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field4">Address</label>
                            <input type="text" class="form-control" id="field4" placeholder="Address"
                                formControlName="address">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field3">City</label>
                            <input type="text" class="form-control" id="field3" placeholder="City"
                                formControlName="city">
                        </div>

                    </div>
                    <div class="form-row row">

                        <div class="form-group col-md-6">
                            <label for="field4">District</label>
                            <input type="text" class="form-control" id="field4" placeholder="district"
                                formControlName="district">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field3">State</label>
                            <input type="text" class="form-control" id="field3" placeholder="State"
                                formControlName="state">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field4">Pincode</label>
                            <input type="number" class="form-control" id="field4" placeholder="Pincode"
                                formControlName="pincode">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field3">Supplier Type</label>
                            <input type="text" class="form-control" id="field3" placeholder="Suppliers"
                                formControlName="suppliertype">
                        </div>
                    </div>
                    <div class="form-row row">
                       
                        <div class="form-group col-md-6">
                            <label for="field4">Message</label>
                            <input type="text" class="form-control" id="field4" placeholder="Enter Message"
                                formControlName="msg">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 text-center pt-30">
                        <button type="submit" class="default-btn page-btn text-center"  style="border: none;">Send Message</button>
                     </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="container mt-5">
    <div class="row">
        <!-- Form 1 -->
        <div class="col-md-6 mb-4">
            <div class="form-container">
                <h4 class="form-heading">DELIVERY BOY FORM</h4>
                <form (ngSubmit)="threeSubmit()" [formGroup]="formthree">
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field1">First Name</label>
                            <input type="text" class="form-control" id="field1" placeholder="Your Name"
                                formControlName="fname">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field2">Last Name</label>
                            <input type="text" class="form-control" id="field2" placeholder="Last Name"
                                formControlName="lname">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field3">Gender</label>
                            <input type="text" class="form-control" id="field3" placeholder="Gender"
                                formControlName="gen">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">Email Address</label>
                            <input type="email" class="form-control" id="field4" placeholder="Email Id"
                                formControlName="email">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field3">Phone Number</label>
                            <input type="number" class="form-control" id="field3" placeholder="Phone Number"
                                formControlName="phn">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">Alternative Phone Number</label>
                            <input type="number" class="form-control" id="field4" placeholder="Alternative Phone Number"
                                formControlName="altphn">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field4">Address</label>
                            <input type="text" class="form-control" id="field4" placeholder="Address"
                                formControlName="address">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field3">City</label>
                            <input type="text" class="form-control" id="field3" placeholder="City"
                                formControlName="city">
                        </div>
                    </div>
                    <div class="form-row row">
                       
                        <div class="form-group col-md-6">
                            <label for="field4">District</label>
                            <input type="text" class="form-control" id="field4" placeholder="district"
                                formControlName="district">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field3">State</label>
                            <input type="text" class="form-control" id="field3" placeholder="State"
                                formControlName="state">
                        </div>
                    </div>
                    <div class="form-row row">
                       
                        <div class="form-group col-md-6">
                            <label for="field4">Pincode</label>
                            <input type="number" class="form-control" id="field4" placeholder="Pincode"
                                formControlName="pincode">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">Job Location(you preffered)</label>
                            <input type="number" class="form-control" id="field4" placeholder="Job Location"
                                formControlName="joblocation">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field3">Qualification</label>
                            <input type="text" class="form-control" id="field3" placeholder="Qualification"
                                formControlName="qualification">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">Smart Phone</label>
                            <input type="text" class="form-control" id="field4" placeholder="Smart Phone"
                                formControlName="smartphn">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field3">Two Wheelers</label>
                            <input type="text" class="form-control" id="field3" placeholder="Wheelers"
                                formControlName="vehicle">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">Licence</label>
                            <input type="text" class="form-control" id="field4" placeholder="Licence"
                                formControlName="licence">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field4">Message</label>
                            <input type="text" class="form-control" id="field4" placeholder="Message"
                                formControlName="msg">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 text-center">
                        <button type="submit" class="default-btn page-btn text-center" style="border: none;">Send Message</button>
                     </div>
                </form>
            </div>
        </div>
        <!-- Form 2 -->
        <div class="col-md-6 mb-4">
            <div class="form-container">
                <h4 class="form-heading">DISTRICT SERVICE SUPERVISOR FORM</h4>
                <form (ngSubmit)="fourSubmit()" [formGroup]="formfour">
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field1">First Name</label>
                            <input type="text" class="form-control" id="field1" placeholder="Your Name"
                                formControlName="fname">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field2">Last Name</label>
                            <input type="text" class="form-control" id="field2" placeholder="Last Name"
                                formControlName="lname">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field3">Gender</label>
                            <input type="text" class="form-control" id="field3" placeholder="Gender"
                                formControlName="gen">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">Email Address</label>
                            <input type="email" class="form-control" id="field4" placeholder="Email Id"
                                formControlName="email">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field3">Phone Number</label>
                            <input type="number" class="form-control" id="field3" placeholder="Phone Number"
                                formControlName="phn">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">Alternative Phone Number</label>
                            <input type="number" class="form-control" id="field4" placeholder="Alternative Phone Number"
                                formControlName="altphn">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field4">Address</label>
                            <input type="text" class="form-control" id="field4" placeholder="Address"
                                formControlName="address">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field3">City</label>
                            <input type="text" class="form-control" id="field3" placeholder="City"
                                formControlName="city">
                        </div>

                    </div>
                    <div class="form-row row">

                        <div class="form-group col-md-6">
                            <label for="field4">District</label>
                            <input type="text" class="form-control" id="field4" placeholder="district"
                                formControlName="district">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field3"> Select State</label>
                            <input type="text" class="form-control" id="field3" placeholder="State"
                                formControlName="state">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field4">Pincode</label>
                            <input type="number" class="form-control" id="field4" placeholder="Pincode"
                                formControlName="pincode">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field3">Job Location(you Preffered)</label>
                            <input type="text" class="form-control" id="field3" placeholder="Job Location"
                                formControlName="joblocation">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field3">Qualification</label>
                            <input type="text" class="form-control" id="field3" placeholder="Qualification"
                                formControlName="qualification">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="field4">Previous Experience</label>
                            <input type="text" class="form-control" id="field4" placeholder="Enter Message"
                                formControlName="experience">
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="field4">Message</label>
                            <input type="text" class="form-control" id="field4" placeholder="Enter Message"
                                formControlName="msg">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 text-center">
                        <button type="submit" class="default-btn page-btn text-center"  style="border: none;">Send Message</button>
                     </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>