<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title">
            <h3>About Us</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-img">
                    <img src="assets/img/about/about.avif" alt="About Images">

                    <div class="about-bg-shape">
                        <img src="assets/img/about/bg-shape.png" alt="About Shape">
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>How Lampsi Transforms Food Delivery?</h2>
                    <p>Lampsi is a premier food ordering and delivery platform based in Andhra Pradesh, India. Inspired by the vision of providing a seamless dining experience, Lampsi connects urban food enthusiasts with a diverse range of neighborhood restaurants. With our exclusive fleet of delivery personnel, we offer unmatched flexibility, including no minimum order requirements and the convenience of online payments for all our partner restaurants. Each order is handled individually by our dedicated delivery team, ensuring fast and reliable service every time.</p>
                </div>
            </div>
        </div>
    </div>
</div>



<app-footer-style-one></app-footer-style-one>